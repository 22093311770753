// $border: #ddd;
// $border-hover: rgb(71,71,71);
// $bg-hover: rgba(0,0,0,.1);
// $text: rgba(102,102,102,1);
// $text-hover: #000;
// $ident: 30px;
// $left: -($ident);
// $first:#DDF3FE;
// $second:#DDEBC8;
// $third:#FEFCD5;
// #tree-view-section {
//     overflow-x: hidden;


// .wtree {
//   li {
//     list-style-type: none;
//     margin:10px 0 10px 10px;
//     position: relative;
//     // width: 100%;
//     // overflow-x: hidden;
//     &:before {
//       content: "";
//       counter-increment: item; 
//       position: absolute;
//       top:-10px;
//       left:$left;
//       border-left: 1px solid $border;
//       border-bottom:1px solid $border;
//       width:$ident;
//       height:15px;
//     }
//     &:after {
//       position:absolute;
//       content:"";
//       top:5px;
//       left:$left;
//       border-left: 1px solid $border;
//       border-top:1px solid $border;
//       width:$ident;
//       height:100%;
//     }
//     &:last-child:after {
//       display:none;
//     }
//     span {
//       display:block;
//       padding:10px;
//       color:$text;
//       text-decoration:none;
//       &:before{ 
//       content: counters(item, ".") " ";    
//     }
//     }
//   }
// }

// .wtree {
//   li {
//     span {
//       &:hover,
//       &:focus { 
//         color: $text-hover; 
//         & + ol {
//           li {
//             span {
//               color: $text-hover; 
//             }
//           }
//         }
//       }

//     //   &:hover + ul, 
//     //   &:focus + ul {
//     //     li {
//     //       &:after,&:before {
//     //         border-color:  $border-hover;
//     //       }
//     //     }
//     //   }
//     }
//   }
// }

// // li span {background-color:$first}
// // li li span{background-color:$second}
// // li li li span{background-color:$third}

// .display{
//   margin-right:12px;
//   font-weight:bold;
// }

// input , label{
//   margin:12px 0px 20px 0px;
// }

// label {
//     padding-left: 6px;
//     padding-right: 12px;
// }

.c-pointer{
  cursor: pointer;
}
.tree-node{
  
}

// #radio-1:checked+label ~ ol li ol li {display: none;}
// #radio-2:checked+label ~ ol li ol li ol li  {display: none;}
// #radio-3:checked+label ~ li ol li ol li {display: none;}

// }