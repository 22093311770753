body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.edit-img .verbiage-img-upload{
position: relative;
}


.edit-img .verbiage-img-upload > svg{
  position: relative;
  top: -50px;
  font-size: 1.6rem;
  color: #ff0000;
  left: -14px;
  cursor: pointer;
  }
  .edit-img .verbiage-img-upload .delete-img-video {
    position: relative;
    top: -95px;
    font-size: 1.6rem;
    color: red;
    right: -82px;
    cursor: pointer;
  }
  

  .login-container {
    background: #e5e5e5;
    width: 600px !important;
    /* padding: 1rem 0.5rem; */
    padding: 3rem !important;
    margin-top: 4rem !important;
    border-radius: 1em;
  }

  .update-container {
    background: #e5e5e5;
    width: 600px !important;
    /* padding: 1rem 0.5rem; */
    padding: 3rem !important;
    margin-top: 4rem !important;
    border-radius: 1em;
  }