@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

$grayLighter: rgba(198, 181, 181, 1);
$grayLight: rgba(83, 83, 83, 1);
$gray: rgba(100, 100, 100, 1);
$lightBorderColor: rgba(204, 204, 204, 1);
$primaryBlue: rgba(38, 138, 255, 1);
$lightGray: rgba(251, 251, 251, 1);
$graySecondary: rgba(238, 238, 238, 1);

html, body {
    font-family: 'Inter', sans-serif;
    overflow-x: hidden;
}

.bg-light-gray {
    background-color: $lightGray;
}

.form-input-div {
    position: relative;
    input {
        border: 1px solid $lightBorderColor;
        padding: 10px 20px;
        width: 100%;
        border-radius: 7px;
        color: $grayLighter;
        &::placeholder {
            color: $grayLighter;
        }
        &:focus, &:active {
            border: 1px solid $lightBorderColor;
            outline: none;
        }
    }
} 

.cursor-pointer {
    cursor: pointer;
}
.nic{
    text-align: right;
}

.relative {
    position: relative;
}

.text-primary {
    color: $primaryBlue;
}

.primaryBtn {
    background-color: rgb(38, 138, 255);
    color: white;
    border-radius: 6px;
    border: none;
    padding: 5px 12px;
}
.primaryBtn:hover {
    color: white; 
    background-color: #0858c8;
    border-color: #0a58ca; 
}
.text-gray-heading {
    color: $grayLight;
    font-size: 16px;
    // display: flex;
    // align-items: center;
    justify-content: center;
}

// .text-gray-light {
//     // color: $grayLighter;
// }

.text-gray {
    color: $gray;
}

.search-icon {
    position: absolute;
    left: 13px;
    top: 8px;
}

.user-icon {
    background-color: rgba(236, 236, 236, 1);
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.main-header-content{
    align-items: center;
}

.user-details h5, .user-details p{
    margin: 0;
}

.btn:focus, .btn:active {
    border: none;
}

.main-form {
    min-height: 50vh;
    max-width: 60%;
    margin: auto;
    padding: 100px 0;
    .form-select {
        width: auto;
        border: none !important;
        padding: 10px 35px 10px 20px;
        // --bs-form-select-bg-img: url('/assets/images/arrow-down-blue.svg');
        &:focus {
            outline: none;
            box-shadow: none;
        }
    }
    input, select {
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px solid $lightBorderColor;
        padding: 10px 20px;
        width: 100%;
        &:focus {
            border-bottom: 1px solid $lightBorderColor;
            outline: none;
        }
        &::placeholder{
            color: $grayLighter;
        }
    }

    .arrow-icon-gray {
        position: absolute;
        right: 10px;
        top: 15px;
    }
}

.options-div {
    position: absolute;
    left: 0;
    top: 100%;
    width: 200px;
    background-color: white;
    border-radius: 4px;
    border: 1px solid $lightBorderColor;
    p {
        margin: 0;
    }
}

.view-mode {
    .accordion {
        border: none;

        .accordion-item {
            border: none;
            border-radius: 5px;
            margin: 15px 0;
        }

        .accordion-item.active {
            border: 1px solid rgba(232, 232, 232, 1);
        }

        .accordion-header .accordion-button {
            padding-left: 80px;

            &:focus {
                box-shadow: none;
            }
        }

        .accordion-button:not(.collapsed) {
            background: none;
            box-shadow: none;
        }

        .accordion-button::after {
            display: none;
        }
       
        .accordion-body {
            margin-left: 60px;
        }

        .btn-check:checked + .btn, .btn-check:checked + .btn {
            border-width: 1px;
        }

        .btn-check + .btn, .btn-check + .btn {
            border-width: 1px;
        }

        .btn:active, .btn:focus {
            border-width: 1px;
        }

        
    }

    nav {
        .pagination {
            gap: 12px;
        }
        
        .page-link {
            color: $grayLighter;
        }

        .page-link.active {
            color: $primaryBlue;
            background: transparent;
        }
    }

}

.view-button-group {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.modal {
    .btn-close:focus {
        box-shadow: none;
    }
    .modal-header {
        border: none;
    }

    .modal-body {
        padding: 0 20px 10px 20px;
    }
}

.MuiOutlinedInput-root .MuiAutocomplete-input{
    border-bottom: none !important;
}

.btn-check .disabled, .btn .disabled{
    cursor: not-allowed;
}

.form-control{
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid rgb(204, 204, 204);
    padding: 10px 20px;
    width: 100%;
    border-radius: 0px;
   

}
.form-control:focus{

    border-top: none;
    border-left: none;
    border-right: none;
    // border-bottom: 1px solid rgb(204, 204, 204);
    padding: 10px 20px;
    width: 100%;

    outline: none !important;
    box-shadow: none !important;
    border-color: blue !important;
}

#set_up_next{
    width: 24px;
    margin: 0px 20px;
    border: 0.5px solid blue;
}


.user-details{
    text-transform: lowercase;
    text-align: left;

    .text-gray-heading{
        text-transform: capitalize;
    }
}

.btn-section{
    margin-top: 47px;
    margin-bottom: 15px;
}

.search-list{
    overflow-x: auto;
    position: absolute;
    background: #ffff;
    width: 100%;
    border: 1px solid #ccc;
    border-top: 0px;
    padding: 13px 39px;
    list-style: none;
    min-height: auto;
    max-height: 300px;
    z-index: 100;
    li{
        padding: 5px 0px;
        cursor: pointer;
    }
    li:hover{
        background-color: #f4f4f4;
        
    }
}

.relative{
    position: relative;
}


#toogle-section{



      
 .toggle-button-cover{
    display: inline-block;
    z-index: 0;
 }
  
  .button-cover:before {
    counter-increment: button-counter;
    content: counter(button-counter);
    position: absolute;
    right: 0;
    bottom: 0;
    color: #d7e3e3;
    font-size: 12px;
    line-height: 1;
    padding: 5px;
  }
  
  .knobs,
  .layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  
  .button {
    position: relative;
    top: 25%;
    width: 56px;
    height: 25px;
    overflow: hidden;
  }
  
  .button.r,
  .button.r .layer {
    border-radius: 100px;
    border: 1px solid #ccc;
  }
  
  .button.b2 {
    border-radius: 2px;
  }
  
  .checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
  }
  
  .knobs {
    z-index: 2;
    background-color: #fff;
  }
  
  .layer {
    width: 100%;
    background-color: #fff;
    transition: 0.3s ease all;
    z-index: 1;
  }
  
  /* Button 1 */
  #button-1 .knobs:before {
    content: "No";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 25px;
    height: 24px;
    color: #fff;
    font-size: 9px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    padding: 9px 4px;
    background-color: #f44336;
    border-radius: 50%;
  }
  
  #button-1 .checkbox:checked + .knobs:before {
    content: "Yes";
    left: 29px;
    background-color: #04dc65;
  }
  
  #button-1 .checkbox:checked ~ .layer {
    background-color: #fcebeb;
  }
  
  #button-1 .knobs,
  #button-1 .knobs:before,
  #button-1 .layer {
    transition: 0.3s ease all;
  }
  
}


.login-logo{
    margin-top: 6rem;
}

.action-section{
    align-items: center;

    .action-icon{
        cursor: pointer;
        width: 50px;
    }
}


.text-truncate-720 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    // width: 720px;
    text-align: left;
    padding: 0px;
}

.align-content-center{
    align-items: center;
}

.ck-content {
    min-height: 150px;
    }
.ck-content .ck-placeholder {
        color: red;
      }

      .accordion-button span {
        display: inline-block;
        margin-left: 0.5rem;
      }
      
      .accordion-button span:first-child {
        display: none;
      }

        .accordion-header{
            position: relative;
         
        }

      .accordion-header .accordion-close{
        background: none;
        content: '-';
        z-index: 10;
        position: absolute;
        font-size: 25px;
        width: 30px;
        height: 30px;
        background: $graySecondary;
        text-align: center;
        color: $grayLight;
        transform: none;
        border-radius: 2px;
        position: absolute;
        left: 20px;
        top: 14px;
        cursor: pointer;
    }

     .accordion-header .accordion-open{
        background: none;
        content: '+';
        cursor: pointer;
        z-index: 10;
        position: absolute;
        font-size: 25px;
        width: 30px;
        height: 30px;
        background: $graySecondary;
        text-align: center;
        color: $grayLight;
        transform: none;
        border-radius: 2px;
        position: absolute;
        left: 20px;
        top: 14px;
    }
    

    #gridContainer {
        // .dx-widget, .dx-widget *, .dx-widget ::after, .dx-widget ::before, .dx-widget::after, .dx-widget::before{
        //     border: none !important;
        // }
        .dx-datagrid-pager {
            border: none !important;
        }
        
        .dx-datagrid-headers + .dx-datagrid-rowsview, .dx-datagrid-headers{
            border: none !important;
        }
        thead, tbody, tfoot, tr, td, th{
            border: none !important;
        }

      .dx-datagrid-nowrap{
            margin-top: 2rem;
        }
        .dx-datagrid .dx-column-lines > td:last-child{
            text-align: right !important;
        }
    //     .dx-datagrid-text-content {
    //         font-weight: bold;
    // color: #494545;
    //     }
     }

     .dx-datagrid-action{
        font-weight: 600;
        font-size: 16px;
        color: #000;
     }

.dx-datagrid-action:last-child{
     text-align: right !important;
}


.action-section{
    justify-content:right !important;
}
     .dx-pager .dx-pages{
        // padding-right: 100px !important;
     }

     .dx-pager .dx-page-sizes .dx-selection, .dx-pager .dx-pages .dx-selection{
    background-color: #0d6efd !important;
    color: #fff !important;
    font-size: 16px;
    font-weight: 500;        
     }


     .dx-column-lines td:last-child{
        text-align: right !important;
     }

     .dx-datagrid-header-panel {
        border-bottom: 1px solid #ddd !important;
    }

   
    .dx-toolbar-items-container{
        top: 3px !important;
    }
   
    .main-form input{
        border-radius: 0px;
    }