.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Tree view  */
.tree-branch-wrapper::marker,
.tree-leaf-list-item::marker {
  /* width: 100%; */
  content: "";
}
.tree-view-container {
  position: relative;
  overflow-x: hidden;
  border-left: 1px solid gray;
}
.tree-view-container:after {
  /* content: '';
  left: 0px;
  height: ; */
}
.tree-branch-wrapper,
.tree-leaf-list-item,
.tree-node {
  position: relative;
}
.tree-branch-wrapper:after,
.tree-leaf-list-item:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  border: 1px solid gray;
  left: -200px;
  top: 19px;
  z-index: -1;
}
/* .tree-node:after{
  position: absolute;
  content: '';
  width: 100%;
  height: 2px;
  border: 1px solid green;
  left: -75px;
  top: 19px;
  z-index: -1;
} */
.tree-node,
ul.tree-node-group .tree-node.tree-node__branch {
  width: 100% !important;
}
.tree-node.tree-node__branch {
  background: #ddf3fe;
  width: max-content;
  padding: 8px;
  margin: 10px 0;
  width: max-content;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
}

ul.tree-node-group .tree-node.tree-node__branch {
  background: #ddebc8;
  padding: 8px;
  margin: 10px 0;
  width: max-content;
  display: flex;
  align-items: center;
}

.tree-node.tree-node__leaf {
  background: #fefcd5 !important;
  padding: 8px;
  margin: 10px 0;
  width: max-content;
  display: flex;
  cursor: pointer;
  align-items: center;
}

.checkbox-icon {
  margin: 0 7px !important;
}
.c-pointer {
  cursor: pointer;
}

.verbiage-img-upload img {
  height: 100px;
  width: 100px;
  object-fit: cover;
  position: relative;
  max-width: 200px;
}
.verbiage-img-upload video {
  height: 100px;
    width: 100px;
    object-fit: cover;
    position: relative;

   /* Ensure the image or video doesn't exceed the container */

}
.verbiage-img-upload .delete-img {
  position: relative;
  top: -146px;
  font-size: 1.6rem;
  color: #ff0000;
  right: -81px;
  cursor: pointer;
}
.verbiage-img-upload .delete-img-video {
  position: relative;
  top: -153px;
  font-size: 1.6rem;
  color: red;
  right: -82px;
  cursor: pointer;
}

#upload-lable{
  cursor: pointer;
  width: 100%;
  padding-top: 20px;
}
.filename {
  text-align: center;
  font-size: 12px;
  margin-top: 4px;
  max-width: 100%; /* Set the maximum width for the filename */
  overflow: hidden; /* Hide any overflow */
  text-overflow: ellipsis; /* Display ellipsis (...) for overflowed text */
  white-space: nowrap; /* Prevent wrapping */
}

.pagination{
  justify-content: end;
  margin-right: 3rem;
}

/* CSS */
.input-container {
  position: relative;
}

.password-toggle-icon {
  position: absolute;
  bottom: 10px;
  right: 39px;
  cursor: pointer;
}
/* Password Input Field */
.password-inputs {
  position: relative;
}

/* Password Toggle Icon */
.password-toggle-icons{
  position: absolute;
  bottom: 8px; 
  right: 12px; 
  cursor: pointer;
}


/* Password Input Field */
.passwords-input {
  position: relative;
}

/* Password Toggle Icon */
.passwords-toggle-icon {
  position: absolute;
  bottom: 8px; 
  right: 12px; 
  cursor: pointer;
}




.gap-3 {
  gap: 15px !important;
}
.p-2 {
  padding: 0.5rem !important;
  object-fit: contain;
}


.react-confirm-alert-body > h1{
  font-size: 1.5rem;
}


.light-box-section > div {
  z-index: 1000;
}

.zoom-controls {
  display: flex;
  justify-content: center;
  margin-top: 10px; /* Adjust the margin as needed */
}

.zoom-controls button {
  background-color: #007bff; /* Button background color */
  color: #fff; /* Button text color */
  border: none;
  padding: 5px 10px;
  margin: 0 10px; /* Adjust the margin as needed */
  cursor: pointer;
  transition: background-color 0.3s ease; /* Add a transition effect */
}

.zoom-controls button:hover {
  background-color: #0056b3; /* Hover background color */
}
.loading-section-110 {
  position: fixed;
  top: 0px;
  background-color: #fff;
  height: 100%;
  width: 100%;
  z-index: 10;

}
.loader-110 {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 0.5s linear infinite;
  position: fixed;
  top: 50%;
  left: 50%;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.form-loader{
  position: absolute;
  background-color: rgba(234, 234, 234, 0.5);
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 10;
  justify-content: center;
  align-items: center;
  display: flex;

}
.form-loader > span {
  /* text-align: center; */
    /* margin: auto auto; */
    /* display: flex; */
    /* align-items: center; */
    /* width: 100%; */
    /* height: 100%; */
    /* justify-content: center; */
    font-size: 22px;
    font-weight: 600;
    /* position: absolute; */
    /* top: 45%; */
    /* left: 38%; */
}


.upload-button {
  background-color: #007BFF;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.upload-button:hover {
  background-color: #0056b3;
}

.uploading-message {
  font-size: 18px;
  margin-top: 20px;
}

.uploading-animation {
  /* display: inline-block;
  width: 50px;
  height: 50px;
  border: 4px solid rgba(0, 123, 255, 0.2);
  border-top: 4px solid #007BFF;
  border-radius: 50%;
  animation: spin 1s linear infinite; */
  margin: 0px auto;
  display: flex;
  width: 80px;
  height: 80px;
  border: 4px solid rgba(0, 123, 255, 0.2);
  border-top: 4px solid #007BFF;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  position: absolute;
  left: 44%;
  top: 38%;
  z-index: 10;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.modal-title{
  font-size: 18px;
}



.dx-column-lines td:last-child{
  text-align: right !important;
}

.dx-column-lines td:last-child > .justify-content-center{
  text-align: right !important;
  justify-content: right !important;
}