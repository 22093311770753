#treev-view-container-12{
    $border: #ddd;
$border-hover: rgb(71,71,71);
$bg-hover: rgba(0,0,0,.1);
$text: rgba(102,102,102,1);
$text-hover: #000;
$ident: 30px;
$left: -($ident);
$first:#DDF3FE;
$second:#DDEBC8;
$third:#FEFCD5;

* {
  margin:0;
  padding:0;
  box-sizing:border-box;
}

body {
  padding:50px;
  font-family:'Helvetica Neue', Helvetica,  Arial, sans-serif;
  letter-spacing: normal;
}
h1{
  padding: 0 0 10px 0;
}
.child-node {
    cursor: pointer;
}
ul {
  margin-left:$ident;
  counter-reset: item;
}

.wtree {
  li {
    list-style-type: none;
    margin:10px 0 10px 10px;
    position: relative;
    
    &:before {
      content: "";
      counter-increment: item; 
      position: absolute;
      top:-10px;
      left:$left;
      border-left: 1px solid $border;
      border-bottom:1px solid $border;
      width:$ident;
      height:15px;
    }
    // &:first-child{
    //     // &:before{
    //     //     display: none;
    //     // }
    // }
    &:after {
      position:absolute;
      content:"";
      top:5px;
      left:$left;
      border-left: 1px solid $border;
      border-top:1px solid $border;
      width:$ident;
      height:100%;
    }
    &:last-child:after {
      display:none;
    }
    span {
      display:block;
      border: 1px solid $border;
      padding:10px;
      color:$text;
      text-decoration:none;
    //   &:before{ 
    //     content: counters(item, ".") " ";    
    //     }
    }
  }
}

.wtree {
  li {
    span {
      &:hover,
      &:focus { 
        color: $text-hover; 
        border: 1px solid $border-hover;
        & + ul {
          li {
            span {
              color: $text-hover; 
              border: 1px solid $border-hover;
            }
          }
        }
      }

      &:hover + ul, 
      &:focus + ul {
        li {
          &:after,&:before {
            border-color:  $border-hover;
          }
        }
      }
    }
  }
}

li span {background-color:$first}
li li span{background-color:$second}
li li li span{background-color:$third}

.display{
  margin-right:12px;
  font-weight:bold;
}

input , label{
  margin:12px 0px 20px 0px;
}

label {
    padding-left: 6px;
    padding-right: 12px;
}
.node-li-1{
    &:before{
        display: none;
    }
}

.text-denger{
  color: red !important; 

}
.disabled-li{
  cursor: not-allowed;
 span{
  background-color: #ccc;
  cursor: not-allowed;
  position: relative;
  &::after{
    content: "";
    background-color: red;
    height: 100%;
    border: 2px solid blue;
    width: 100%;
    top: 0px;
    position: absolute;
    opacity: 0.1;
    left: 0px;
  }
  }
}
}

